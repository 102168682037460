/* tslint:disable */
/* eslint-disable */
/**
 * API Explorer
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PaginatedResultOfCityDto } from '../models';
// @ts-ignore
import { ResultOfAddressByZipCodeDto } from '../models';
/**
 * AddressApi - axios parameter creator
 * @export
 */
export const AddressApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} zipcode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressFullAddressByZipCode: async (zipcode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zipcode' is not null or undefined
            assertParamExists('addressFullAddressByZipCode', 'zipcode', zipcode)
            const localVarPath = `/api/v1.1/Address/ZipCodes/{zipcode}`
                .replace(`{${"zipcode"}}`, encodeURIComponent(String(zipcode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [stateId] 
         * @param {string} [zipCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressGetCities: async (stateId?: number, zipCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.1/Address/Cities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (stateId !== undefined) {
                localVarQueryParameter['stateId'] = stateId;
            }

            if (zipCode !== undefined) {
                localVarQueryParameter['zipCode'] = zipCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AddressApi - functional programming interface
 * @export
 */
export const AddressApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AddressApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} zipcode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressFullAddressByZipCode(zipcode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultOfAddressByZipCodeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressFullAddressByZipCode(zipcode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [stateId] 
         * @param {string} [zipCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressGetCities(stateId?: number, zipCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResultOfCityDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressGetCities(stateId, zipCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AddressApi - factory interface
 * @export
 */
export const AddressApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AddressApiFp(configuration)
    return {
        /**
         * 
         * @param {string} zipcode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressFullAddressByZipCode(zipcode: string, options?: any): AxiosPromise<ResultOfAddressByZipCodeDto> {
            return localVarFp.addressFullAddressByZipCode(zipcode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [stateId] 
         * @param {string} [zipCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressGetCities(stateId?: number, zipCode?: string, options?: any): AxiosPromise<PaginatedResultOfCityDto> {
            return localVarFp.addressGetCities(stateId, zipCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AddressApi - object-oriented interface
 * @export
 * @class AddressApi
 * @extends {BaseAPI}
 */
export class AddressApi extends BaseAPI {
    /**
     * 
     * @param {string} zipcode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public addressFullAddressByZipCode(zipcode: string, options?: AxiosRequestConfig) {
        return AddressApiFp(this.configuration).addressFullAddressByZipCode(zipcode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [stateId] 
     * @param {string} [zipCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public addressGetCities(stateId?: number, zipCode?: string, options?: AxiosRequestConfig) {
        return AddressApiFp(this.configuration).addressGetCities(stateId, zipCode, options).then((request) => request(this.axios, this.basePath));
    }
}
