import messages from '../locales';
import dayjs from 'dayjs';
import { QuoteVersion } from '@/api/sales/request/sales.request';
import { QuoteProcedureItemDto } from '@/models/quote-procedure-item-dto';
import { QuoteAccessoryItemDto } from '@/models/quote-accessory-item-dto';
import { FinancedPaymentDto } from '@/models';
import { Mask } from 'maska';
import { ref } from 'vue';
export const getImage = (value) => {
    return `${import.meta.env.VITE_MEDIA_URL}${value}`;
};

export const getBrowserLocale = (options = {}) => {
    const defaultOptions = { countryCodeOnly: true };
    const opt = { ...defaultOptions, ...options };
    const navigatorLocale =
        navigator.languages != undefined ? navigator.languages[0] : navigator.language;
    if (!navigatorLocale) return undefined;
    const trimmedLocale = opt.countryCodeOnly
        ? navigatorLocale.trim().split(/-|_/)[0]
        : navigatorLocale.trim();
    return trimmedLocale;
};

export const supportedLocalesInclude = (locale) => {
    return Object.keys(messages).includes(locale);
};

export const printHtml = () => {
    return window.print();
};

export const unMaskPhone = (phone: string): string => {
    if (phone) return phone.replace(/[-()\s+]/g, '');
    return phone;
};

export const parseTo12hTime = (dateTime: string) =>
    dayjs(dateTime, 'YYYY-MM-DDTHH:mm:ss').format('hh:mm A');

export const isVersion = (version: number, versions: Array<number>) => {
    return versions.some((e) => e === version);
};
export const isFinancedQuote = (version: number) => {
    return version == QuoteVersion.FINANCED;
};
export const isFullPaid = (item: QuoteProcedureItemDto | QuoteAccessoryItemDto) => {
    return item.paid >= Number((item.price * item.quantity).toFixed(2));
};
export const isFullPaidFee = (item: FinancedPaymentDto) => {
    return item.paid >= item.maxAmount;
};
export const somePayment = (paid: number) => {
    return paid > 0;
};

export function formatDatePicker(dateString) {
    const date = dayjs(dateString);
    if (!dateString) return '';
    return date.format('YYYY-MM-DD');
}
export class ErrorResult {
    private message: string;
    private data: any;
    private status: number;
    constructor(message, status, data = {}) {
        this.message = message;
        this.data = data;
        this.status = status;
    }
    toString() {
        return `${this.message}`;
    }
    isSystemException() {
        return this.status === 500;
    }
    isCanceledError() {
        return this.data?.code === 'ERR_CANCELED';
    }
}

export const mask = (value: string, format: string) => {
    if (value) {
        const m = new Mask({ mask: format, eager: true });
        return m.masked(value);
    }
    return '';
};

export const hasBarcodePermission = import.meta.env.VITE_BARCODE_FLAG == 1;
export const surgeryProcessActivated = import.meta.env.VITE_PROCESS_FLAG == 1;
export const notification = ref<string>('');
export const refreshUnread = ref<boolean>(false);

export const setNotification = (message: string) => (notification.value = message);

export const refreshNotification = (value: boolean) => (refreshUnread.value = value);
export const coolDownTimer = (cooldown: number, f: (value: number | undefined) => void) => {
    const interval = ref();
    interval.value = setInterval(() => {
        if (cooldown === 0) {
            clearInterval(interval.value);
            f(undefined);
        } else if (cooldown) {
            cooldown = cooldown - 1;
            f(cooldown);
        }
    }, 1000);
};
