/* tslint:disable */
/* eslint-disable */
/**
 * API Explorer
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddApplicationUserCommand } from '../models';
// @ts-ignore
import { PaginatedResultOfApplicationUserDto } from '../models';
// @ts-ignore
import { ResetUserPasswordCommand } from '../models';
// @ts-ignore
import { Result } from '../models';
// @ts-ignore
import { ResultOfApplicationUserDto } from '../models';
// @ts-ignore
import { SortDirection } from '../models';
// @ts-ignore
import { UpdateApplicationUserCommand } from '../models';
/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary GET: api/Users/5.
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGetApplicationUser: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.1/Users/ApplicationUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [email] 
         * @param {boolean} [active] 
         * @param {string} [fullName] 
         * @param {string} [role] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [filter] 
         * @param {string} [sortLabel] 
         * @param {SortDirection} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGetApplicationUsers: async (email?: string, active?: boolean, fullName?: string, role?: string, page?: number, pageSize?: number, filter?: string, sortLabel?: string, sortDirection?: SortDirection, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1.1/Users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (email !== undefined) {
                localVarQueryParameter['Email'] = email;
            }

            if (active !== undefined) {
                localVarQueryParameter['Active'] = active;
            }

            if (fullName !== undefined) {
                localVarQueryParameter['FullName'] = fullName;
            }

            if (role !== undefined) {
                localVarQueryParameter['Role'] = role;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (sortLabel !== undefined) {
                localVarQueryParameter['SortLabel'] = sortLabel;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['SortDirection'] = sortDirection;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddApplicationUserCommand} addApplicationUserCommand 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersRegisterApplicationUser: async (addApplicationUserCommand: AddApplicationUserCommand, xRequestid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addApplicationUserCommand' is not null or undefined
            assertParamExists('usersRegisterApplicationUser', 'addApplicationUserCommand', addApplicationUserCommand)
            const localVarPath = `/api/v1.1/Users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xRequestid != null) {
                localVarHeaderParameter['x-requestid'] = String(xRequestid);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addApplicationUserCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ResetUserPasswordCommand} resetUserPasswordCommand 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersResetApplicationUserPassword: async (id: string, resetUserPasswordCommand: ResetUserPasswordCommand, xRequestid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersResetApplicationUserPassword', 'id', id)
            // verify required parameter 'resetUserPasswordCommand' is not null or undefined
            assertParamExists('usersResetApplicationUserPassword', 'resetUserPasswordCommand', resetUserPasswordCommand)
            const localVarPath = `/api/v1.1/Users/{id}/ResetPassword`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xRequestid != null) {
                localVarHeaderParameter['x-requestid'] = String(xRequestid);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetUserPasswordCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateApplicationUserCommand} updateApplicationUserCommand 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUpdateApplicationUser: async (id: string, updateApplicationUserCommand: UpdateApplicationUserCommand, xRequestid?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersUpdateApplicationUser', 'id', id)
            // verify required parameter 'updateApplicationUserCommand' is not null or undefined
            assertParamExists('usersUpdateApplicationUser', 'updateApplicationUserCommand', updateApplicationUserCommand)
            const localVarPath = `/api/v1.1/Users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xRequestid != null) {
                localVarHeaderParameter['x-requestid'] = String(xRequestid);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateApplicationUserCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary GET: api/Users/5.
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersGetApplicationUser(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultOfApplicationUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersGetApplicationUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [email] 
         * @param {boolean} [active] 
         * @param {string} [fullName] 
         * @param {string} [role] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [filter] 
         * @param {string} [sortLabel] 
         * @param {SortDirection} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersGetApplicationUsers(email?: string, active?: boolean, fullName?: string, role?: string, page?: number, pageSize?: number, filter?: string, sortLabel?: string, sortDirection?: SortDirection, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResultOfApplicationUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersGetApplicationUsers(email, active, fullName, role, page, pageSize, filter, sortLabel, sortDirection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddApplicationUserCommand} addApplicationUserCommand 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersRegisterApplicationUser(addApplicationUserCommand: AddApplicationUserCommand, xRequestid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersRegisterApplicationUser(addApplicationUserCommand, xRequestid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {ResetUserPasswordCommand} resetUserPasswordCommand 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersResetApplicationUserPassword(id: string, resetUserPasswordCommand: ResetUserPasswordCommand, xRequestid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersResetApplicationUserPassword(id, resetUserPasswordCommand, xRequestid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateApplicationUserCommand} updateApplicationUserCommand 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUpdateApplicationUser(id: string, updateApplicationUserCommand: UpdateApplicationUserCommand, xRequestid?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUpdateApplicationUser(id, updateApplicationUserCommand, xRequestid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary GET: api/Users/5.
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGetApplicationUser(id?: string, options?: any): AxiosPromise<ResultOfApplicationUserDto> {
            return localVarFp.usersGetApplicationUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [email] 
         * @param {boolean} [active] 
         * @param {string} [fullName] 
         * @param {string} [role] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [filter] 
         * @param {string} [sortLabel] 
         * @param {SortDirection} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGetApplicationUsers(email?: string, active?: boolean, fullName?: string, role?: string, page?: number, pageSize?: number, filter?: string, sortLabel?: string, sortDirection?: SortDirection, options?: any): AxiosPromise<PaginatedResultOfApplicationUserDto> {
            return localVarFp.usersGetApplicationUsers(email, active, fullName, role, page, pageSize, filter, sortLabel, sortDirection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddApplicationUserCommand} addApplicationUserCommand 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersRegisterApplicationUser(addApplicationUserCommand: AddApplicationUserCommand, xRequestid?: string, options?: any): AxiosPromise<Result> {
            return localVarFp.usersRegisterApplicationUser(addApplicationUserCommand, xRequestid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ResetUserPasswordCommand} resetUserPasswordCommand 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersResetApplicationUserPassword(id: string, resetUserPasswordCommand: ResetUserPasswordCommand, xRequestid?: string, options?: any): AxiosPromise<Result> {
            return localVarFp.usersResetApplicationUserPassword(id, resetUserPasswordCommand, xRequestid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateApplicationUserCommand} updateApplicationUserCommand 
         * @param {string} [xRequestid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUpdateApplicationUser(id: string, updateApplicationUserCommand: UpdateApplicationUserCommand, xRequestid?: string, options?: any): AxiosPromise<Result> {
            return localVarFp.usersUpdateApplicationUser(id, updateApplicationUserCommand, xRequestid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary GET: api/Users/5.
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersGetApplicationUser(id?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersGetApplicationUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [email] 
     * @param {boolean} [active] 
     * @param {string} [fullName] 
     * @param {string} [role] 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [filter] 
     * @param {string} [sortLabel] 
     * @param {SortDirection} [sortDirection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersGetApplicationUsers(email?: string, active?: boolean, fullName?: string, role?: string, page?: number, pageSize?: number, filter?: string, sortLabel?: string, sortDirection?: SortDirection, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersGetApplicationUsers(email, active, fullName, role, page, pageSize, filter, sortLabel, sortDirection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddApplicationUserCommand} addApplicationUserCommand 
     * @param {string} [xRequestid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersRegisterApplicationUser(addApplicationUserCommand: AddApplicationUserCommand, xRequestid?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersRegisterApplicationUser(addApplicationUserCommand, xRequestid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {ResetUserPasswordCommand} resetUserPasswordCommand 
     * @param {string} [xRequestid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersResetApplicationUserPassword(id: string, resetUserPasswordCommand: ResetUserPasswordCommand, xRequestid?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersResetApplicationUserPassword(id, resetUserPasswordCommand, xRequestid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateApplicationUserCommand} updateApplicationUserCommand 
     * @param {string} [xRequestid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUpdateApplicationUser(id: string, updateApplicationUserCommand: UpdateApplicationUserCommand, xRequestid?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersUpdateApplicationUser(id, updateApplicationUserCommand, xRequestid, options).then((request) => request(this.axios, this.basePath));
    }
}
